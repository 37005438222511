import styled, { css } from 'styled-components';
import { media } from 'styles';

export const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${media.desktop`
    justify-content: flex-end;
    padding: 0;
    width: inherit;
  `}
`;

export const SocialMediaButton = styled.a`
  align-items: center;
  background-color: ${props =>
    (props.title === 'Facebook' &&
      css`
        ${({ theme }) => theme.main.colors.facebook}
      `) ||
    (props.title === 'Instagram' &&
      css`
        ${({ theme }) => theme.main.colors.instagram}
      `) ||
    (props.title === 'Twitter' &&
      css`
        ${({ theme }) => theme.main.colors.twitter}
      `) ||
    (props.title === 'Youtube' &&
      css`
        ${({ theme }) => theme.main.colors.youtube}
      `) ||
    (props.title === 'Linkedin' &&
      css`
        ${({ theme }) => theme.main.colors.linkedin}
      `)};
  border-radius: 50%;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: 0 0.25rem;
  width: 2rem;

  .box-icon {
    color: white;
  }
`;
