import React, { useState } from 'react';
import { bool } from 'prop-types';
import { slugify, VAGAS_SEARCH_URL } from 'utils';
import * as S from './styled';

const InputSearch = ({ hideOnMobile }) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = event => setInputValue(event.target.value);

  const redirectSearch = () =>
    (window.location = `${VAGAS_SEARCH_URL}${slugify(inputValue)}`);

  const handleEnterPress = event =>
    event.keyCode === 13 ? redirectSearch() : null;

  return (
    <S.SearchBox hideOnMobile={hideOnMobile}>
      <S.SearchInput
        type="text"
        placeholder="Digite um cargo, cidade ou estado"
        onChange={handleChange}
        onKeyDown={handleEnterPress}
      />
      <S.SearchIcon onClick={redirectSearch}>
        <i className="bx bx-search bx-sm"></i>
      </S.SearchIcon>
    </S.SearchBox>
  );
};

InputSearch.propTypes = {
  hideOnMobile: bool,
};

InputSearch.defaultProps = {
  hideOnMobile: false,
};

export default InputSearch;
