import React from 'react';
import { bool } from 'prop-types';
import LinkButton from 'components/LinkButton';
import * as S from './styled';
const { VAGAS_URL } = require('../../../env-config');

const MenuMobile = ({ isActive }) => {
  return (
    <S.AsideMenuMobile isActive={isActive}>
      <S.AsideLinksWrapper>
        <LinkButton
          type="primary"
          label="Cadastre-se Gratuitamente"
          url={`${VAGAS_URL}/cadastro-de-curriculo`}
          target="_self"
        />
        <LinkButton
          type="outlined-grey"
          label="Entrar"
          url={`${VAGAS_URL}/login-candidatos`}
          target="_self"
        />
      </S.AsideLinksWrapper>
      <S.AsideLinksBlock>
        <a href={`${VAGAS_URL}/ajuda-candidatos`} title="Ajuda">
          <S.BoxIconWrapper>
            <i className="bx bx-help-circle bx-sm"></i>
          </S.BoxIconWrapper>
          <span>Ajuda</span>
        </a>
      </S.AsideLinksBlock>
      <S.AsideLinksBlock>
        <S.AsideLinksBlockTitle>VAGAS.com.br</S.AsideLinksBlockTitle>
        <a href={`${VAGAS_URL}/como-funciona`} title="Como Funciona">
          <span>Como Funciona</span>
        </a>
        <a
          href={`${VAGAS_URL}/empresas-contratando`}
          title="Empresas contratando"
        >
          <span>Empresas contratando</span>
        </a>
      </S.AsideLinksBlock>
      <S.AsideLinksBlock>
        <S.AsideLinksBlockTitle>Para a sua carreira</S.AsideLinksBlockTitle>
        <a href={`${VAGAS_URL}/profissoes/`} title="Vagas profissões">
          <span>Vagas Profissões</span>
        </a>
        <a href={`${VAGAS_URL}/mapa-de-carreiras/`} title="Mapas de Carreiras">
          <span>Mapa Vagas de Carreira</span>
        </a>
      </S.AsideLinksBlock>
    </S.AsideMenuMobile>
  );
};

MenuMobile.propTypes = {
  isActive: bool.isRequired,
};

export default MenuMobile;
