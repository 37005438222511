import React, { useContext } from 'react';
import { object } from 'prop-types';
import uuid from 'uuid';
import FooterListLinks from '../FooterListLinks';
import * as S from './styled';
import * as Context from 'context';

const FooterRowContent = ({ theme }) => {
  const { main } = theme;
  const { footerContext } = Context;
  const footerLinks = useContext(footerContext);

  return (
    <S.FooterRowWrapper theme={{ main }}>
      <S.FooterContainer>
        {footerLinks.map(el => (
          <FooterListLinks theme={{ main }} content={el} key={uuid()} />
        ))}
      </S.FooterContainer>
    </S.FooterRowWrapper>
  );
};

FooterRowContent.propTypes = {
  theme: object.isRequired,
};
export default FooterRowContent;
