import React from 'react';
import { oneOf } from 'prop-types';
import * as S from './styled';

const Logo = ({ type = 'header' }) => (
  <S.LogoStyled type={type}>
    {type === 'header' ? (
      <svg width="140" height="72" viewBox="0 0 140 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.505 25.1539L10.1136 40.7361L3.50005 25.1539H0L8.61317 44.243H11.2915L19.7859 25.1539H16.505Z" fill="white"/>
        <path d="M92.3589 32.9847C88.9106 32.1707 87.894 31.5587 87.894 30.1296C87.894 28.5384 89.4371 27.7183 91.9937 27.7183C94.1006 27.6661 96.1705 28.2817 97.9103 29.4778L97.5055 26.0903C96.0842 25.3161 94.1363 24.9091 92.1093 24.9091C87.7693 24.9091 84.8475 27.0297 84.8475 30.3347C84.8475 33.3519 86.6736 34.9034 91.0136 35.8827C94.8667 36.6967 95.8833 37.5137 95.8833 39.1448C95.8833 40.7758 94.3006 41.5928 91.5006 41.5928C88.9749 41.6459 86.5188 40.7588 84.604 39.1019L85.0088 42.8138C86.9171 43.9155 88.9441 44.4051 91.5402 44.4051C95.9624 44.4051 98.9237 42.2416 98.9237 38.8969C98.9237 35.5522 97.2285 34.1261 92.3589 32.9847Z" fill="white"/>
        <path d="M42.2744 33.4989C42.2744 38.4594 46.1093 41.9908 50.8998 41.9908C52.0239 42.0161 53.1418 41.815 54.1875 41.3994C55.2333 40.9838 56.1857 40.3621 56.9889 39.5708C57.792 38.7795 58.4296 37.8347 58.8642 36.7919C59.2987 35.7491 59.5213 34.6295 59.519 33.4989C59.5264 32.4293 59.2866 31.3726 58.8187 30.4119C58.3508 29.4513 57.6674 28.613 56.8225 27.9631L59.5616 27.1675V24.9703H51.2376C46.4927 24.9703 42.2744 28.6088 42.2744 33.4989ZM56.3872 33.4989C56.3872 36.6294 53.9524 39.2336 50.9089 39.2336C47.8654 39.2336 45.4914 36.6294 45.4914 33.4989C45.4914 30.3684 47.8958 27.8866 50.9089 27.8866C51.6369 27.8865 52.3575 28.0323 53.0288 28.3154C53.7001 28.5985 54.3086 29.0133 54.8186 29.5355C55.3287 30.0577 55.7301 30.6769 55.9994 31.3569C56.2687 32.0369 56.4006 32.764 56.3872 33.4958V33.4989Z" fill="white"/>
        <path d="M78.6781 28.3333C76.9737 26.1729 74.338 24.9489 71.2945 24.9489C66.1022 24.9489 61.8809 29.1106 61.8809 34.6984C61.8809 40.3688 66.0596 44.4082 71.2945 44.4082C72.6425 44.4303 73.9792 44.157 75.2117 43.6075C76.4442 43.0579 77.5429 42.2451 78.4315 41.2256L78.8546 44.2429H81.6151V25.1539H78.8455L78.6781 28.3333ZM71.7358 41.5102C68.0014 41.5102 65.1222 38.5725 65.1222 34.6984C65.1222 30.8243 68.0075 27.8407 71.7358 27.8407C75.5493 27.8407 78.4711 30.7753 78.4711 34.6923C78.4917 35.5894 78.3319 36.4815 78.0013 37.315C77.6707 38.1486 77.1761 38.9064 76.5472 39.5431C75.9182 40.1797 75.1678 40.6822 74.3411 41.0202C73.5143 41.3582 72.6282 41.5249 71.7358 41.5102V41.5102Z" fill="white"/>
        <path d="M52.7776 44.0104H45.8657V46.7645H52.7897C53.4831 46.7645 54.1481 47.0414 54.6384 47.5344C55.1287 48.0274 55.4041 48.696 55.4041 49.3931C55.4041 50.0903 55.1287 50.7589 54.6384 51.2519C54.1481 51.7448 53.4831 52.0218 52.7897 52.0218H52.5615V55.0146H52.7867C55.8941 55.0146 58.6394 52.5665 58.6394 49.4696C58.6394 46.3728 56.0098 44.0104 52.7776 44.0104Z" fill="white"/>
        <path d="M36.3641 28.3579C34.6597 26.1974 32.024 24.9734 28.9805 24.9734C23.7883 24.9734 19.5669 29.1352 19.5669 34.7229C19.5669 40.3933 23.7457 44.4327 28.9805 44.4327C30.3301 44.4559 31.6685 44.1833 32.9026 43.6336C34.1367 43.084 35.2369 42.2707 36.1267 41.2502L36.5497 44.2675H39.3072V25.1754H36.5406L36.3641 28.3579ZM29.431 41.5348C25.6965 41.5348 22.8174 38.597 22.8174 34.7229C22.8174 30.8488 25.6965 27.8683 29.431 27.8683C33.2445 27.8683 36.1632 30.806 36.1632 34.7229C36.1834 35.6194 36.0234 36.5108 35.6928 37.3436C35.3621 38.1764 34.8677 38.9335 34.239 39.5696C33.6104 40.2056 32.8605 40.7075 32.0343 41.0452C31.2082 41.3829 30.3227 41.5494 29.431 41.5348Z" fill="white"/>
      </svg>
    ) : (
      <svg width="100" height="32" viewBox="0 0 100 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.505 1.15393L10.1136 16.7361L3.50005 1.15393H0L8.61317 20.243H11.2915L19.7859 1.15393H16.505Z" fill="#2B2B2B"/>
        <path d="M92.3589 8.98472C88.9106 8.17073 87.894 7.5587 87.894 6.12963C87.894 4.53836 89.4371 3.71825 91.9937 3.71825C94.1006 3.66608 96.1705 4.28166 97.9103 5.47782L97.5055 2.09027C96.0842 1.31605 94.1363 0.909058 92.1093 0.909058C87.7693 0.909058 84.8475 3.02972 84.8475 6.33465C84.8475 9.35194 86.6736 10.9034 91.0136 11.8827C94.8667 12.6967 95.8833 13.5137 95.8833 15.1448C95.8833 16.7758 94.3006 17.5928 91.5006 17.5928C88.9749 17.6459 86.5188 16.7588 84.604 15.1019L85.0088 18.8138C86.9171 19.9155 88.9441 20.4051 91.5402 20.4051C95.9624 20.4051 98.9237 18.2416 98.9237 14.8969C98.9237 11.5522 97.2285 10.1261 92.3589 8.98472Z" fill="#2B2B2B"/>
        <path d="M42.2744 9.49891C42.2744 14.4594 46.1093 17.9908 50.8998 17.9908C52.0239 18.0161 53.1418 17.815 54.1875 17.3994C55.2333 16.9838 56.1857 16.3621 56.9889 15.5708C57.792 14.7795 58.4296 13.8347 58.8642 12.7919C59.2987 11.7491 59.5213 10.6295 59.519 9.49891C59.5264 8.42929 59.2866 7.37256 58.8187 6.41194C58.3508 5.45132 57.6674 4.61305 56.8225 3.96314L59.5616 3.16751V0.970337H51.2376C46.4927 0.970337 42.2744 4.60883 42.2744 9.49891ZM56.3872 9.49891C56.3872 12.6294 53.9524 15.2336 50.9089 15.2336C47.8654 15.2336 45.4914 12.6294 45.4914 9.49891C45.4914 6.3684 47.8958 3.88664 50.9089 3.88664C51.6369 3.88651 52.3575 4.03227 53.0288 4.31537C53.7001 4.59848 54.3086 5.01327 54.8186 5.5355C55.3287 6.05773 55.7301 6.67693 55.9994 7.35691C56.2687 8.0369 56.4006 8.76404 56.3872 9.49584V9.49891Z" fill="#2B2B2B"/>
        <path d="M78.6781 4.33335C76.9737 2.1729 74.338 0.948853 71.2945 0.948853C66.1022 0.948853 61.8809 5.11062 61.8809 10.6984C61.8809 16.3688 66.0596 20.4082 71.2945 20.4082C72.6425 20.4303 73.9792 20.157 75.2117 19.6075C76.4442 19.0579 77.5429 18.2451 78.4315 17.2256L78.8546 20.2429H81.6151V1.15388H78.8455L78.6781 4.33335ZM71.7358 17.5102C68.0014 17.5102 65.1222 14.5725 65.1222 10.6984C65.1222 6.82429 68.0075 3.84067 71.7358 3.84067C75.5493 3.84067 78.4711 6.77533 78.4711 10.6923C78.4917 11.5894 78.3319 12.4815 78.0013 13.315C77.6707 14.1486 77.1761 14.9064 76.5472 15.5431C75.9182 16.1797 75.1678 16.6822 74.3411 17.0202C73.5143 17.3582 72.6282 17.5249 71.7358 17.5102Z" fill="#2B2B2B"/>
        <path d="M52.7776 20.0104H45.8657V22.7645H52.7897C53.4831 22.7645 54.1481 23.0414 54.6384 23.5344C55.1287 24.0274 55.4041 24.696 55.4041 25.3931C55.4041 26.0903 55.1287 26.7589 54.6384 27.2519C54.1481 27.7448 53.4831 28.0218 52.7897 28.0218H52.5615V31.0146H52.7867C55.8941 31.0146 58.6394 28.5665 58.6394 25.4696C58.6394 22.3728 56.0098 20.0104 52.7776 20.0104Z" fill="#2B2B2B"/>
        <path d="M36.3641 4.35788C34.6597 2.19744 32.024 0.973389 28.9805 0.973389C23.7883 0.973389 19.5669 5.13515 19.5669 10.7229C19.5669 16.3933 23.7457 20.4327 28.9805 20.4327C30.3301 20.4559 31.6685 20.1833 32.9026 19.6336C34.1367 19.084 35.2369 18.2707 36.1267 17.2502L36.5497 20.2675H39.3072V1.17535H36.5406L36.3641 4.35788ZM29.431 17.5348C25.6965 17.5348 22.8174 14.597 22.8174 10.7229C22.8174 6.84882 25.6965 3.86826 29.431 3.86826C33.2445 3.86826 36.1632 6.80598 36.1632 10.7229C36.1834 11.6194 36.0234 12.5108 35.6928 13.3436C35.3621 14.1764 34.8677 14.9335 34.239 15.5696C33.6104 16.2056 32.8605 16.7075 32.0343 17.0452C31.2082 17.3829 30.3227 17.5494 29.431 17.5348Z" fill="#2B2B2B"/>
      </svg>
    )}
  </S.LogoStyled>
);

Logo.propTypes = {
  type: oneOf(['header', 'footer']),
};

Logo.defaultProps = {
  type: 'header',
};

export default Logo;
