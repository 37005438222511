import styled, { css } from 'styled-components';
import { Container, media, sizes } from 'styles';

export const FooterAllRightsReserved = styled.span`
  display: none;

  ${media.desktop`
    display: inline;
  `}
`;

export const FooterContainer = styled(Container)`
  border-bottom: 1px solid ${({ theme }) => theme.main.colors.lightGrey};
  border-top: 1px solid ${({ theme }) => theme.main.colors.lightGrey};
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1rem 1rem;

  @media (max-width: ${sizes.desktop}px) {
    & {
      max-width: 100%;
    }
  }

  ${media.desktop`
    border-bottom: 0;
    border-top: 0;
  `}
`;

export const FooterContentText = styled.div`
  color: ${({ theme }) => theme.main.colors.grey};
  display: flex;
  justify-content: space-between;
  width: 100%;

  span > span {
    display: none;

    ${media.desktop`
      display: inline;
    `}
  }

  ${media.desktop`
    ${props =>
      props.footerComplete &&
      css`
        border-top: 2px solid ${({ theme }) => theme.main.colors.lightestGrey};
      `}
    justify-content: flex-start;
    ${props =>
      props.footerComplete &&
      css`
        padding-top: 0.75rem;
      `}
  `}
`;

export const FooterRowWrapper = styled.div`
  background-color: ${({ theme }) => theme.main.colors.lightestGrey};
  order: 1;

  ${media.desktop`
    background-color: ${({ theme }) => theme.main.colors.white};
    order: 3;
  `}
`;

export const FooterTermsOfUse = styled.a`
  color: ${({ theme }) => theme.main.colors.blue};

  ${media.desktop`
    color: ${({ theme }) => theme.main.colors.blue};
  `}
`;

export const FooterPrivacyPolicy = styled.a`
  color: ${({ theme }) => theme.main.colors.blue};

  margin-left: 4px;

  ${media.desktop`
    color: ${({ theme }) => theme.main.colors.blue};
  `}
`;
