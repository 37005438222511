import React from 'react';
import { bool, object } from 'prop-types';
import { format } from 'date-fns';
import * as S from './styled';
const { VAGAS_URL } = require('../../../../env-config');

const FooterRowCopyright = ({ theme, footerComplete }) => {
  const { main } = theme;
  const currentYear = format(new Date(), 'yyyy');

  return (
    <S.FooterRowWrapper theme={{ main }}>
      <S.FooterContainer theme={{ main }}>
        <S.FooterContentText footerComplete={footerComplete}>
          <span>
            Copyright &copy;
            {` ${currentYear} `}
            VAGAS <span>Tecnologia.</span> &nbsp;
          </span>
          <S.FooterAllRightsReserved>
            {`Todos os direitos reservados.`}
            &nbsp;
          </S.FooterAllRightsReserved>
          <span>
            <S.FooterTermsOfUse
              href="https://www.vagas.com.br/candidatos/termos-de-uso"
              title={`Termos de Uso`}
            >
              {`Termos de Uso`}
            </S.FooterTermsOfUse>
            <S.FooterPrivacyPolicy
              href="https://www.vagas.com.br/politica-privacidade"
              title="Política de Privacidade"
            >
              Política de Privacidade
            </S.FooterPrivacyPolicy>
          </span>
        </S.FooterContentText>
      </S.FooterContainer>
    </S.FooterRowWrapper>
  );
};

FooterRowCopyright.defaultProps = {
  footerComplete: false,
};

FooterRowCopyright.propTypes = {
  footerComplete: bool,
  theme: object.isRequired,
};

export default FooterRowCopyright;
