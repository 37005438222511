import styled, { css } from 'styled-components';
import { Container, media } from 'styles';
import { sizes } from '../../styles/theme';

export const HeaderTopLinks = styled.div`
  background-color: ${({ theme }) => theme.main.colors.white};
  display: none;
  margin: 0 auto;
  max-width: 1000px;
  padding: 12px 0;
  width: 100%;

  ${media.desktop`
    display: block;
  `}
`;

export const TopLink = styled.a`
  border-right: 1px solid ${({ theme }) => theme.main.colors.lightestGrey};
  color: ${props =>
    (props.type === 'institutional' &&
      css`
        ${({ theme }) => theme.main.colors.institutional}
      `) ||
    (props.type === 'candidates' &&
      css`
        ${({ theme }) => theme.main.colors.candidates}
      `) ||
    (props.type === 'forbusiness' &&
      css`
        ${({ theme }) => theme.main.colors.forbusiness}
      `)};
  font-size: 16px;
  margin-right: 10px;
  padding-right: 10px;

  &:last-child {
    border: none;
  }
`;

export const HeaderWrapper = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.main.colors.greenRebrand};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  transition: 0.3s ease all;
  width: 100%;
  z-index: 10;

  ${props =>
    props.isScrolling &&
    css`
      position: fixed;
      top: 0;
      transition: 0.3s ease all;
    `}
`;

export const HeaderContainer = styled(Container)`
  align-items: center;
  display: ${props => (props.isMobile ? 'none' : 'flex')};
  height: 72px;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderSearchContainer = styled(Container)`
  align-items: center;
  display: none;
  height: 72px;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${sizes.tablet}px) {
    display: flex;
  }
`;

export const HeaderMobileContent = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: space-between;

  > button {
    background: transparent;
    border: none;
    margin: 0 0 0 10px;
    color: white;
  }

  ${media.desktop`
    display: none;
  `}
`;

export const HeaderDesktopContent = styled.div`
  display: none;
  justify-content: end;
  width: 100%;

  ${media.desktop`
    display: flex;
  `}
`;

export const HeaderButtonsWrapper = styled.div`
  display: none;
  justify-content: space-between;

  ${media.desktop`
    display: flex;
  `}
`;

export const HeaderHelpLink = styled.a`
  align-items: center;
  color: ${({ theme }) => theme.main.colors.white};
  display: none;
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;

  span {
    margin: 0 5px;
  }

  ${media.desktop`
    display: flex;
  `}
`;

export const HeaderMobileInputSearch = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.main.colors.greenRebrand};
  bottom: 0;
  display: flex;
  height: 70px;
  left: 0;
  opacity: 0;
  padding: 0 16px;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s ease all;
  visibility: hidden;

  > button {
    background: transparent;
    border: none;
    margin-right: 10px;
    color: white;
  }

  ${props =>
    props.isVisible &&
    css`
      opacity: 1;
      transition: 0.3s ease all;
      visibility: visible;
      z-index: 1;
    `}
`;
