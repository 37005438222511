import styled, { css } from 'styled-components';

export const AsideMenuMobile = styled.aside`
  background: ${({ theme }) => theme.main.colors.white};
  border-radius: 0px;
  display: block;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0px;
  transform: translateX(100%) translateX(70px);
  transition: transform 0.3s linear;
  width: calc(100% - 70px);
  z-index: 20;

  ${props =>
    props.isActive &&
    css`
      transform: translateX(53px) translateX(0);
      transition: transform 0.3s linear;
    `}
`;

export const AsideLinksWrapper = styled.div`
  background-color: ${({ theme }) => theme.main.colors.lightestGrey};
  padding: 16px;

  > a[type='primary'] {
    margin-bottom: 16px;
  }
`;

export const AsideLinksBlock = styled.div`
  border-top: 1px solid ${({ theme }) => theme.main.colors.lightestGrey};
  padding: 16px;

  > a {
    align-items: center;
    color: ${({ theme }) => theme.main.colors.darkGrey};
    display: flex;
    font-size: 16px;
  }

  span ~ a {
    color: ${({ theme }) => theme.main.colors.green};
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const AsideLinksBlockTitle = styled.span`
  display: block;
  font-weight: ${({ theme }) => theme.main.fonts.weight.bolder};
  margin-bottom: 10px;
`;

export const BoxIconWrapper = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.main.colors.primary};
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-right: 10px;
  width: 30px;
  color: white;
`;
