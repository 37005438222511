import React from 'react';
import { bool, object } from 'prop-types';
import Logo from 'components/Logo';
import SocialMediaList from 'components/SocialMediaList';
import * as S from './styled';
const { VAGAS_URL } = require('../../../../env-config');

const FooterRowMain = ({ theme, footerComplete }) => {
  const { main } = theme;

  return (
    <S.FooterRowWrapper theme={{ main }}>
      <S.FooterContainer>
        <S.FooterCol>
          <S.FooterLogoLink href={VAGAS_URL} title="VAGAS.com.br">
            <Logo type="footer" />
          </S.FooterLogoLink>
        </S.FooterCol>
        <S.FooterCol alignment="right">
          { (footerComplete) && <SocialMediaList /> }
        </S.FooterCol>
      </S.FooterContainer>
    </S.FooterRowWrapper>
  );
};

FooterRowMain.defaultProps = {
  footerComplete: false,
};

FooterRowMain.propTypes = {
  footerComplete: bool,
  theme: object.isRequired,
};
export default FooterRowMain;
