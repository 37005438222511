import styled, { css } from 'styled-components';
import { Container, media } from 'styles';

export const FooterCol = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${props => props.alignment === 'right' && css`
    justify-content: flex-end;
  `}
  width: 100%;

  ${media.desktop`
    flex-direction: row;
  `}
`;

export const FooterContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 0 10px 0;

  ${media.desktop`
    background-color: ${({ theme }) => (theme.main.colors.lightestGrey)};
    flex-direction: row;
    padding: 10px;
  `}
`;

export const FooterLogo = styled.img`
  height: 25px;
  width: 88px;
`;

export const FooterLogoLink = styled.a`
  display: none;

  ${media.desktop`
    display: inherit;
  `}
`;

export const FooterRowWrapper = styled.div`
  background-color: ${({ theme }) => (theme.main.colors.lightestGrey)};
  order: 3;

  ${media.desktop`
    order: 1;
  `}
`;
