import React from 'react';
import { bool, object } from 'prop-types';

import FooterRowContent from './FooterRowContent';
import FooterRowCopyright from './FooterRowCopyright';
import FooterRowMain from './FooterRowMain';
import * as S from './styled';

const Footer = ({ theme, footerComplete }) => {
  const { main } = theme;
  const isFooterComplete = () => (
    (footerComplete) && <FooterRowContent theme={{ main }} />
  );

  return (
    <S.FooterWrapper>
      <FooterRowMain theme={{ main }} footerComplete={footerComplete} />
      { isFooterComplete() }
      <FooterRowCopyright theme={{ main }} footerComplete={footerComplete} />
    </S.FooterWrapper>
  );
};

Footer.defaultProps = {
  footerComplete: true,
};

Footer.propTypes = {
  footerComplete: bool,
  theme: object.isRequired,
};

export default Footer;
