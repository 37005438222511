import React, { useContext } from 'react';
import uuid from 'uuid';
import * as S from './styled';
import * as Context from 'context';

const SocialMediaList = () => {
  const { socialMediaContext } = Context;
  const networks = useContext(socialMediaContext);

  return (
    <S.SocialMediaWrapper>
      {Object.values(networks).map(obj => (
        <S.SocialMediaButton
          color={obj.color}
          href={obj.url}
          key={uuid()}
          title={obj.title}
          target="_blank"
          aria-label={`Ícone ${obj.title}`}
          rel="noopener noreferrer"
        >
          <i className={`bx bxl-${obj.icon} bx-sm box-icon`}></i>
        </S.SocialMediaButton>
      ))}
    </S.SocialMediaWrapper>
  )
};

export default SocialMediaList;
