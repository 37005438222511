import React, { useContext } from 'react';
import { headerContext } from 'context';
import * as S from './styled';

const TopLinks = () => {
  const topLinks = useContext(headerContext);

  return (
    <S.HeaderTopLinks>
      {topLinks.map(link => (
        <S.TopLink
          key={link.url}
          type={link.type}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link.label}
        </S.TopLink>
      ))}
    </S.HeaderTopLinks>
  );
};

export default TopLinks;
