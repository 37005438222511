import React, { Fragment, useState, useEffect, useRef } from 'react';
import { object } from 'prop-types';
import Logo from 'components/Logo';
import InputSearch from 'components/InputSearch';
import LinkButton from 'components/LinkButton';
import MenuMobile from 'components/MenuMobile';
import * as S from './styled';
import useWindowSize from 'hooks/useWindowSize';
const { VAGAS_URL } = require('../../../env-config');

const Header = ({ theme }) => {
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
  const [isActiveMobileSearch, setIsActiveMobileSearch] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const windowGlobal = typeof window !== 'undefined' && window;

  const { main } = theme;
  const overlayNode = useRef();

  const toggleMobileMenu = () => {
    setIsActiveMobileMenu(!isActiveMobileMenu);
  };

  const toggleMobileInputSearch = () => {
    setIsActiveMobileSearch(!isActiveMobileSearch);
  };

  const closeWithOutsideClick = e => {
    if (overlayNode.current.contains(e.target)) return;
    setIsActiveMobileMenu(false);
  };

  useEffect(() => {
    const fixedHeader = () => {
      if (windowGlobal.scrollY > (isMobile ? 0 : 43.6)) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    document.addEventListener('click', closeWithOutsideClick);
    document.addEventListener('scroll', fixedHeader);

    return () => {
      document.removeEventListener('click', closeWithOutsideClick);
      document.removeEventListener('scroll', fixedHeader);
    };
  }, [windowGlobal, isMobile]);

  return (
    <Fragment>
      <S.HeaderWrapper
        id="VG_HEADER"
        theme={{ main }}
        ref={overlayNode}
        isScrolling={scrolling}
      >
        <S.HeaderContainer>
          <a
            href={VAGAS_URL}
            title="VAGAS.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo type="header" />
          </a>
          <S.HeaderDesktopContent>
            <S.HeaderButtonsWrapper>
              <S.HeaderHelpLink
                href={`${VAGAS_URL}/ajuda-candidatos`}
                title="Ajuda a Candidatos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bx bx-help-circle bx-sm"></i>
                <span>Ajuda</span>
              </S.HeaderHelpLink>
              <LinkButton
                type="outlined"
                label="Entrar"
                url={`${VAGAS_URL}/login-candidatos`}
                target="_self"
              />
              <LinkButton
                type="primary"
                label="Cadastre-se Gratuitamente"
                url={`${VAGAS_URL}/cadastro-de-curriculo`}
                target="_self"
              />
            </S.HeaderButtonsWrapper>
          </S.HeaderDesktopContent>
          <S.HeaderMobileContent>
            <button
              type="button"
              aria-label="Icone de Busca"
              onClick={toggleMobileInputSearch}
            >
              <i className="bx bx-search bx-md"></i>
            </button>
            <button
              type="button"
              aria-label="Icone de Menu"
              onClick={toggleMobileMenu}
            >
              <i className="bx bx-menu bx-md"></i>
            </button>
          </S.HeaderMobileContent>
          <S.HeaderMobileInputSearch isVisible={isActiveMobileSearch}>
            <button onClick={toggleMobileInputSearch}>
              <i className="bx bx-left-arrow-alt bx-md"></i>
            </button>
            <InputSearch />
          </S.HeaderMobileInputSearch>
          <MenuMobile isActive={isActiveMobileMenu} />
        </S.HeaderContainer>
        <S.HeaderSearchContainer>
          <InputSearch />
        </S.HeaderSearchContainer>
      </S.HeaderWrapper>
    </Fragment>
  );
};

Header.propTypes = {
  theme: object.isRequired,
};

export default Header;
