import styled from 'styled-components';
import { Container, media, sizes } from 'styles';

export const FooterContainer = styled(Container)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;

  @media (max-width: ${sizes.desktop}px) {
    & {
      max-width: 100%;
    }
  }

  ${media.desktop`
    flex-direction: row;
    padding: 1.5rem 1rem;
  `}
`;

export const FooterRowWrapper = styled.div`
  background-color: ${({ theme }) => (theme.main.colors.white)};
  order: 2;
`;
