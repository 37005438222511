/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Fragment } from 'react';
import { node } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from 'styles';
import Header from 'components/Header';
import Content from 'components/Content';
import Footer from 'components/Footer';
import TopLinks from '../TopLinks';
import { Placeholder } from 'components/Placeholder';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Fragment>
          <GlobalStyle />
          <TopLinks />
          <Header theme={theme} siteTitle={data.site.siteMetadata.title} />
          <Content>
            <main>{children}</main>
          </Content>
          <Footer theme={theme} footerComplete={true} />
        </Fragment>
      </ThemeProvider>
    </Fragment>
  );
};

Layout.propTypes = {
  children: node.isRequired,
};

export default Layout;
