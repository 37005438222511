import styled, { css } from 'styled-components';
import { media } from 'styles';

export const HeaderTopLinks = styled.div`
  background-color: ${({ theme }) => theme.main.colors.white};
  display: none;
  margin: 0 auto;
  max-width: 1000px;
  padding: 12px 0;
  width: 100%;

  ${media.desktop`
    display: block;
  `}
`;

export const TopLink = styled.a`
  border-right: 1px solid ${({ theme }) => theme.main.colors.lightestGrey};
  color: ${props =>
    (props.type === 'institutional' &&
      css`
        ${({ theme }) => theme.main.colors.institutional}
      `) ||
    (props.type === 'candidates' &&
      css`
        ${({ theme }) => theme.main.colors.candidates}
      `) ||
    (props.type === 'forbusiness' &&
      css`
        ${({ theme }) => theme.main.colors.forbusiness}
      `)};
  font-size: 16px;
  margin-right: 10px;
  padding-right: 10px;

  &:last-child {
    border: none;
  }
`;
