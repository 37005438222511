import React from 'react';
import { oneOfType, node, object } from 'prop-types';
import ContentContainer from './styled';

const Content = ({ children }) => (
  <ContentContainer>
    {children}
  </ContentContainer>
);

Content.propTypes = {
  children: oneOfType([
    node, object,
  ]).isRequired,
};

export default Content;
