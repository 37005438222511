import styled from 'styled-components';
import { media } from 'styles';

export const FooterList = styled.nav`
  border-top-color: ${({ theme }) => (theme.main.colors.lightestGrey)};
  border-top-style: solid;
  border-top-width: ${props => (props.openList ? '1px' : '0')};
  display: ${props => (props.openList ? 'block' : 'none')};

  ${media.desktop`
    display: block;
  `}
`;

export const FooterListAnchor = styled.a`
  color: ${({ theme }) => (theme.main.colors.grey)};
  display: block;
  line-height: 1.6em;
  padding: 1rem;
  
  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }

  > img {
    display: block;
    margin: 0 auto;
    width: 120px;

  ${media.desktop`
    margin: 0;
  `}    
  }

  ${media.desktop`
    padding: .25rem 0;
  `}
`;

export const FooterListLinksWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => (theme.main.colors.lightestGrey)};
  width: 100%;

  ${media.desktop`
    border: 0;
    padding-right: 1rem;
  `}
`;

export const FooterMainAnchor = styled.a`
  color: ${({ theme }) => (theme.main.colors.lightGreen)};
  display: flex;
  justify-content: space-between;
  line-height: 1.6em;
  padding: .75rem 1rem;
  position: relative;

  .box-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  
  ${media.desktop`
    padding: .5rem 0 1rem 0;
  `}
`;

export const BoxIconWrapper = styled.span`
  ${media.desktop`
    display: none;
  `}
`;
