import styled, { css } from 'styled-components';
import { media } from 'styles';

export const SearchBox = styled.div`
  height: 40px;
  position: relative;
  width: 100%;

  ${props => props.hideOnMobile && css`
    display: none;

    ${media.desktop`
      display: flex;
    `}
  `}
`;

export const SearchInput = styled.input`
  border: none;
  border-radius: 4px;
  height: 40px;
  padding: 8px 16px;
  width: 100%;
`;

export const SearchIcon = styled.span`
  background-color: ${({ theme }) => (theme.main.colors.white)};
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 8px;
`;