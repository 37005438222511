import styled from 'styled-components';
import { Container } from 'styles';

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
`;

export const FooterContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;
