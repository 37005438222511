import React, { useState } from 'react';
import { object } from 'prop-types';
import uuid from 'uuid';
import * as S from './styled';

const FooterListLinks = ({ theme, content }) => {
  const { mainTitle, mainUrl, links } = content;
  const { main } = theme;
  const windowGlobal = typeof window !== 'undefined' && window;

  const [openList, setOpenList] = useState(false);

  const isMobile = windowGlobal.innerWidth < main.sizes.desktop || false;

  const toggleList = e => {
    if (isMobile) e.preventDefault();
    setOpenList(!openList);
  };

  return (
    <S.FooterListLinksWrapper>
      <S.FooterMainAnchor href={mainUrl} onClick={toggleList}>
        {mainTitle}
        <S.BoxIconWrapper>
          <i
            className={`bx bx-xs ${
              openList ? 'bx-chevron-up' : 'bx-chevron-down'
            }`}
          ></i>
        </S.BoxIconWrapper>
      </S.FooterMainAnchor>

      <S.FooterList openList={openList} key={uuid()}>
        {Object.values(links).map(obj => (
          <S.FooterListAnchor
            href={obj.url}
            key={uuid()}
            title={obj.title}
            target={obj?.anotherTab ? '_blank' : '_self'}
            rel="noreferrer noopener"
          >
            {obj?.img ? (
              <img src={obj.img} alt={obj.imgAlt} />
            ) : (
              <span>{obj.title}</span>
            )}
          </S.FooterListAnchor>
        ))}
      </S.FooterList>
    </S.FooterListLinksWrapper>
  );
};

FooterListLinks.propTypes = {
  content: object.isRequired,
  theme: object.isRequired,
};

export default FooterListLinks;
