import styled from 'styled-components';
import { media } from 'styles';

const ContentContainer = styled.div`
  background: ${({ theme }) => (theme.main.colors.white)};
  min-height: 78vh;
  width: 100%;

  h1 {
    color: ${({ theme }) => (theme.main.colors.darkGrey)};
    font-size: 24px;
    font-weight: ${({ theme }) => (theme.main.fonts.weight.normal)};
    text-align: center;
  }

  ${media.desktop`
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    margin: 0 auto;
    min-width: 343px;
    padding: 0 0 40px;
  `}
`;

export default ContentContainer;
